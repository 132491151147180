import '../index.css'
// import React from "react"
import React, {useState, useRef} from 'react'


export default function Hair_form(){
    const [formData,setFormData] = React.useState(
        { 
            Name:"", //Input box
            Mobile:"",
            Area:"",//Input box

            Gender_group:"", //radio
            Age_group:"",//radio
            Mode:"",//radio
            Timeslot:"",//radio
            Allergies:"",//radio

            curr:"",//Current treatments/medications, Input box

            Hair_treatment:"",// Select box for treatments list 

            comments:"" //comment box
        }
    )
    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                // [name]: type === "checkbox" ? checked : value
                [name]:value
            }
        })
    }
    console.log(formData)
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbxYQ2mAp0U7Vz28eVeJNjyQsu6oNtFTNKtVubqTAQC76aMM-qA_Rl6kir3sFNtyffx8/exec"
    const [loading, setLoading] = useState(false)
    function handleSubmit(event){
        event.preventDefault()
        const success = document.getElementById('success')
        success.style.display='block';
        console.log(formData)
        fetch(scriptUrl, {
            method: 'POST', 
            body: new FormData(formRef.current),
    
        }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
            .catch(err => console.log(err))
    }
    return(
        <>
            <div className="hair--form">
            <div id="success"><h6>YOUR INFORMATION IS SUBMITTED SUCCESSFULLY! <br />AWAIT A QUICK RESPONSE FROM K'ARE!! </h6></div>
            <div className="hair--form--container" id="hair_f">
                <h2>Hair Form</h2>
                <h6>LOVE IS IN THE HAIR !</h6>

                
                <div className="hair--form--section">
                    <div className="hair--form--1">
                        <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                            <div className="hair--form--list">
                                <label>
                                    NAME * &nbsp;
                                
                                    <input
                                        id="name"
                                        type="text" 
                                        placeholder="Full Name"
                                        onChange={handleChange}
                                        name="Name"
                                        value={formData.Name}
                                        required
                                    />
                                </label>
                                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                <label>
                                    MOBILE * &nbsp;
                                
                                    <input
                                        id="mobile"
                                        type="text"
                                        placeholder="Enter 10 Digits"
                                        onChange={handleChange}
                                        name="Mobile"
                                        value={formData.Mobile}
                                        required
                                    />
                                </label>
                                
                            </div>
                            <div className="hair--area">
                                <label>
                                    AREA  * &nbsp;
                                
                                    <input
                                        id="area"
                                        type="text"
                                        placeholder="Enter Your Area/Locality"
                                        onChange={handleChange}
                                        name="Area"
                                        value={formData.Area}
                                    />
                                </label>
                            </div>
                            
                            <div className="hair--form--radios">
                                {/* <h3>PREFERRED MODE OF CONTACT</h3> */}
                                <div className='gender'>
                                <h6>Gender</h6>
                                    <input 
                                        type="radio"
                                        id="male"
                                        name="Gender_group"
                                        value="male"
                                        checked={formData.Gender_group === "male"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="Male">Male</label>
                                    <input 
                                        type="radio"
                                        id="female"
                                        name="Gender_group"
                                        value="female"
                                        checked={formData.Gender_group === "female"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="female">Female</label>
                                    <input 
                                        type="radio"
                                        id="prefernottoanswer"
                                        name="Gender_group"
                                        value="prefernottoanswer"
                                        checked={formData.Gender_group === "prefernottoanswer"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="prefernottoanswer">Prefer not to answer</label>
                                </div>
                                <div className='gender'>
                                <h6>Age</h6>
                                    <input 
                                        type="radio"
                                        id="fifteenplus"
                                        name="Age_group"
                                        value="15 +"
                                        checked={formData.Age_group === "15 +"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="fifteenplus">15 +</label>
                                    <input 
                                        type="radio"
                                        id="eighteenplus"
                                        name="Age_group"
                                        value="18 - 24"
                                        checked={formData.Age_group === "18 - 24"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="eighteenplus">18 - 24</label>
                                    <input 
                                        type="radio"
                                        id="twentyfiveplus"
                                        name="Age_group"
                                        value="25 - 31"
                                        checked={formData.Age_group === "25 - 31"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="twentyfiveplus">25 - 31</label>
                                    <input 
                                        type="radio"
                                        id="thirtytwoplus"
                                        name="Age_group"
                                        value="32 - 38"
                                        checked={formData.Age_group === "32 - 38"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="thirtytwoplus">32 - 38</label>
                                    <input 
                                        type="radio"
                                        id="thirtynineplus"
                                        name="Age_group"
                                        value="39 +"
                                        checked={formData.Age_group === "39 +"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="thirtynineplus">39 +</label>
                                </div>
                                <div className='gender'>
                                <h6>Preferred Mode of Contact</h6>
                                    <input 
                                        type="radio"
                                        id="call"
                                        name="Mode"
                                        value="call"
                                        checked={formData.Mode === "call"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="call">Call</label>
                                    <input 
                                        type="radio"
                                        id="msg"
                                        name="Mode"
                                        value="msg"
                                        checked={formData.Mode === "msg"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="msg">Message</label>
                                    <input 
                                        type="radio"
                                        id="chat"
                                        name="Mode"
                                        value="chat"
                                        checked={formData.Mode === "chat"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="chat">WhatsApp Chat</label>

                                {/* <h3>PREFERRED TIME SLOT</h3> */}
                                </div>
                                <div className='gender'>
                                <h6>Preferred Time Slot</h6>
                                    <input 
                                        type="radio"
                                        id="morning"
                                        name="Timeslot" 
                                        value="morning 10am-12pm"
                                        checked={formData.Timeslot ==="morning 10am-12pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="morning">10am- 12pm</label>

                                    <input 
                                        type="radio"
                                        id="noon"
                                        name="Timeslot"
                                        value="noon 2pm-5pm"
                                        checked={formData.Timeslot ==="noon 2pm-5pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="noon">2pm - 5pm</label>

                                    <input 
                                        type="radio"
                                        id="eve"
                                        name="Timeslot"
                                        value="eve 5pm-8pm"
                                        checked={formData.Timeslot ==="eve 5pm-8pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="eve">5pm - 8pm</label>
                                </div>
                                <div className='gender'>
                                <h6>Allergies, if any</h6>
                                    <input 
                                        type="radio"
                                        id="alle-yes"
                                        name="Allergies" 
                                        value="Yes"
                                        checked={formData.Allergies ==="Yes"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="alle-yes">Yes</label>

                                    <input 
                                        type="radio"
                                        id="alle-no"
                                        name="Allergies"
                                        value="No"
                                        checked={formData.Allergies ==="No"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="alle-no">No</label>

                                    <input 
                                        type="radio"
                                        id="not-sure"
                                        name="Allergies"
                                        value="not_sure"
                                        checked={formData.Allergies ==="not_sure"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="not-sure">Not Sure</label>
                                </div>
                                </div>
                                <div className="hair--curr">
                                <label>
                                    Current Treatment / Medications &nbsp;&nbsp;
                                
                                    <input
                                        id="curr"
                                        type="text" 
                                        placeholder="Mention Current Treatments / Medications, if any"
                                        onChange={handleChange}
                                        name="curr"
                                        value={formData.curr}
                
                                    />
                                </label>
                                </div>

                                <div className="hair--treatments--select">
                                    <label htmlFor='h_treatment'>Select Your Preffered Treatment &nbsp;</label>
                                    <br />
                                    <select
                                        id="h_treatment"
                                        value={formData.Hair_treatment}
                                        onChange={handleChange}
                                        name="Hair_treatment"
                                    >
                                        <option value="">--Choose--</option>
                                        <option value="Hair Transplantation">Hair Transplantation</option>
                                        <option value="Anti-Dandruff">Anti-Dandruff</option>
                                        <option value="Premature Grey Hair">Premature Grey Hair</option>
                                        <option value="Hair Restoration">Hair Restoration</option>
                                        <option value="Scalp Micropigmentation">Scalp Micropigmentation</option>
                                        

                                    </select>
                                </div>

                                <div className="hair--form--comments">
                                    <h6>Concerns / Comments</h6>
                                    {/* <label htmlFor="comments">CONCERNS/COMMENTS</label> */}
                                    <textarea
                                        id="comments"
                                        value={formData.comments}
                                        placeholder="Comments, if any ..."
                                        onChange={handleChange}
                                        name="comments">
                                    </textarea> 
                                </div>
                                <div className="hair--form--button">
                                    {/* <button id="submit" name="submit">Submit</button> */}
                                    <input id="submit" name="submit" type="submit" value={loading ? "Loading..." : "Submit"}/>
                                </div>

                                
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

