import '../index.css'
import React from "react"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import icon_s_1 from "../images/icon_s_1.png"
import icon_s_2 from "../images/icon_s_2.png"
import icon_s_3 from "../images/icon_s_3.png"
import icon_s_4 from "../images/icon_s_4.png"
import icon_s_5 from "../images/icon_s_5.png"
import icon_s_6 from "../images/icon_s_6.png"
import icon_s_7 from "../images/icon_s_7.png"
import icon_s_8 from "../images/icon_s_8.png"
import icon_s_9 from "../images/icon_s_9.png"
import icon_s_10 from "../images/icon_s_10.png"
import icon_s_11 from "../images/icon_s_11.png"
import icon_h_1 from "../images/icon_h_1.png"
import icon_h_2 from "../images/icon_h_2.png"
import icon_h_3 from "../images/icon_h_3.png"
import icon_h_4 from "../images/icon_h_4.png"
import icon_h_5 from "../images/icon_h_5.png"
import icon_d_1 from "../images/icon_d_1.png"
import icon_d_2 from "../images/icon_d_2.png"
import icon_d_3 from "../images/icon_d_3.png"
import icon_d_4 from "../images/icon_d_4.png"
import icon_d_5 from "../images/icon_d_5.png"
import icon_d_6 from "../images/icon_d_6.png"
import icon_d_7 from "../images/icon_d_7.png"
import icon_d_8 from "../images/icon_d_8.png"
import icon_n_1 from "../images/icon_n_1.png"
import icon_n_2 from "../images/icon_n_2.png"
import icon_n_3 from "../images/icon_n_3.png"
import icon_n_4 from "../images/icon_n_4.png"
import icon_n_5 from "../images/icon_n_5.png"



export default function Treatments(){
    return(
        <>
        <section>
        <h1 className="t--heading">K'ARE SPECIALITY TREATMENTS</h1>
        <div className="wave waves"></div>
        </section>
        
            <div className="treatments--container">
                <section>
                <div className="skin--t--container">
                    <div className="skin--first">    
                    </div>
                    <h2 className="t--subheading">SKIN TREATMENTS</h2>
                    <div className="treatment--list--container">
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_1} alt="medi-facial-icon" ></img>
                            <h4 className="s--t--heading">MEDI-FACIAL</h4>
                            <p className="s--t--explanation">Aesthetic Medi-Facial improves skin tone, texture, and collagen production by using advanced technology, research protocols, and science-based ingredients, thus, slowing down the aging process</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_2} alt="chemical-peel-icon" ></img>
                            <h4 className="s--t--heading">CHEMICAL PEEL</h4>
                            <p className="s--t--explanation">Cosmetic Chemical-Peels remove the top layer of your skin resulting in smooth skin re-growth revealing more youthful skin</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_3} alt="glow-drip-icon" ></img>
                            <h4 className="s--t--heading">GLOW DRIP</h4>
                            <p className="s--t--explanation">Glow Drips replenishes your skin with vitamins, thus, promoting younger, brighter, and glowing skin. It is the quickest beauty boost for your glass-like glowing skin</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_4} alt="vampire-facial-icon" ></img>
                            <h4 className="s--t--heading">VAMPIRE-FACIAL</h4>
                            <p className="s--t--explanation">Vampire-Facial is a collagen induction therapy through a combination of micro-needling and PRP, which improves fine lines/wrinkles/skin texture / under eye bags and reduces pore size</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_5} alt="skin-rejuvenation-icon" ></img>
                            <h4 className="s--t--heading">SKIN-REJUVENATION</h4>
                            <p className="s--t--explanation">Skin-Rejuvenation is a skin resurfacing and revitalizing technique that fixes textural irregularities and reverses the damaging effects aging has on the skin</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_6} alt="skin-booster-icon" ></img>
                            <h4 className="s--t--heading">SKIN BOOSTER</h4>
                            <p className="s--t--explanation">Skin-Boosters are healers of skin and help in balancing skin moisture, enhancing tissue regeneration, and producing increased collagen and elastin which results in smooth and radiant skin</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_7} alt="anti-ageing-icon" ></img>
                            <h4 className="s--t--heading">ANTI-AGING</h4>
                            <p className="s--t--explanation">Anti-aging treatments use Multi-Vitamin Skin-Awakening complexes and are aimed at slowing, preventing, and even reversing the skin aging process</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_8} alt="de-pigmentation-icon" ></img>
                            <h4 className="s--t--heading">DE-PIGMENTATION</h4>
                            <p className="s--t--explanation">De-Pigmentation is an advanced treatment aiming to eliminate and reduce dark spots on your skin that emanate from melanin. De-Pigmentation unifies the skin tone and boosts skin luminosity</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_9} alt="acne-scars-icon" ></img>
                            <h4 className="s--t--heading">ACNE / ACNE SCARS</h4>
                            <p className="s--t--explanation">Acne and Acne-Scar treatments help in minimizing the appearance of acne scars and even remove them altogether by using light therapy or chemical peels following the skin texture</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_10} alt="sundamage-removal-icon" ></img>
                            <h4 className="s--t--heading">SUN DAMAGE REVERSAL</h4>
                            <p className="s--t--explanation">Sun Damage Reversal helps reverse the sun damage caused by sunburn / over- exposure to the sun. This treatment makes the skin healthier, also reducing age spots and pigmentation</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_s_11} alt="skintag-removal-icon" ></img>
                            <h4 className="s--t--heading">SKIN TAG REMOVAL</h4>
                            <p className="s--t--explanation">Skin Tag-Removal treatments eliminate the cosmetic discomfort of skin tags by removing them permanently by using the techniques that are apt for skin accordingly</p>
                        </div>        
                    </div>
                </div>
                <div className="wave waves"></div>
                </section>
                        
                
                <section>
                <div className="hair--t--container">
                    <div className="hair--first">
                    </div>
                    <h2 className="t--subheading">HAIR TREATMENTS</h2>
                    <div className="treatment--list--container">
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_h_1} alt="HAIR TRANSPLANTATION-icon" ></img>
                            <h4 className="s--t--heading">HAIR TRANSPLANTATION</h4>
                            <p className="s--t--explanation">The hair Transplant procedure repairs hair loss by transferring hair from a healthy part of the scalp to balding areas of the scalp</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_h_2} alt="ANTI-DANDRUFF-icon" ></img>
                            <h4 className="s--t--heading">ANTI-DANDRUFF</h4>
                            <p className="s--t--explanation">Anti-Dandruff treatments care for your hair by cleansing dandruff on the scalp alongside fixing dry, damaged, and lifeless hair</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_h_3} alt="PREMATURE GREY HAIR-icon" ></img>
                            <h4 className="s--t--heading">PREMATURE GREY HAIR</h4>
                            <p className="s--t--explanation">Premature Grey Hair treatments aid in slowing, reversing, and getting rid of premature graying of hairs caused due to various factors including vitamin deficiencies and genes</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_h_4} alt="HAIR RESTORATION -icon" ></img>
                            <h4 className="s--t--heading">HAIR RESTORATION (PRP)(GFC)</h4>
                            <p className="s--t--explanation">PRP is a novel procedure that uses your healing cells to reverse hair loss and grow new hair. GFC hair treatment uses highly concentrated growth factor preparation to promote hair growth</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_h_5} alt="SCALP MICROPIGMENTATION-icon" ></img>
                            <h4 className="s--t--heading">SCALP MICROPIGMENTATION</h4>
                            <p className="s--t--explanation">Scalp Micropigmentation is a cosmetic treatment for thinning hair or hair loss. The procedure involves depositing tiny dots of pigment on the scalp giving the appearance of thick hair</p>
                        </div>    
                    </div>
                </div>
                <div className="wave waves"></div>
                </section>

                <section>
                <div className="dental--t--container">
                    <div className="dental--first">
                    </div>
                    <h2 className="t--subheading">DENTAL TREATMENTS</h2>
                    <div className="treatment--list--container">
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_1} alt="SMILE DESIGNING-icon" ></img>
                            <h4 className="s--t--heading">SMILE DESIGNING</h4>
                            <p className="s--t--explanation">Smile Design is a cosmetic/orthodontic procedure based on the aesthetics of the mouth and the face that gives you a pleasing smile apace with a positive influence on the overall esthetics of the face</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_2} alt="TEETH WHITENING-icon" ></img>
                            <h4 className="s--t--heading">TEETH WHITENING</h4>
                            <p className="s--t--explanation">Teeth Whitening is a procedure that aids in removing stains from the tooth surface, in turn, restoring your teeth to white and bright</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_3} alt="ALIGNERS-icon" ></img>
                            <h4 className="s--t--heading">ALIGNERS & BRACES</h4>
                            <p className="s--t--explanation">Aligners/Braces are the tools that facilitate alignment of the improperly positioned tooth, crowding, or crooked teeth</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_4} alt="TOOTH IMPLANTS<-icon" ></img>
                            <h4 className="s--t--heading">TOOTH IMPLANTS</h4>
                            <p className="s--t--explanation">Tooth Implants are fixtures that replace tooth roots in turn aiding the replacement of damaged or missing teeth</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_5} alt="ROOT CANAL-icon" ></img>
                            <h4 className="s--t--heading">ROOT CANAL</h4>
                            <p className="s--t--explanation">Root Canal is designed to eliminate bacteria from the infected root canal and is performed to save the damaged or badly infected tooth instead of completely extracting it</p>
                        </div>    
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_6} alt="TOOTH EXTRACTION-icon" ></img>
                            <h4 className="s--t--heading">TOOTH EXTRACTION</h4>
                            <p className="s--t--explanation">Tooth Extraction serves in eliminating bacteria/infections by completely removing the tooth. This procedure is preferred only when the tooth damage is past the point of repair</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_7} alt="COSMETIC DENTISTRY-icon" ></img>
                            <h4 className="s--t--heading">COSMETIC DENTISTRY</h4>
                            <p className="s--t--explanation">Cosmetic Dentistry is aimed at creating a positive change in your teeth and your smile by using cosmetic dentistry procedures to fit your needs and wants</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_d_8} alt="DENTURES-icon" ></img>
                            <h4 className="s--t--heading">DENTURES</h4>
                            <p className="s--t--explanation">Dentures are a removable replacement for missing teeth fitted to act as prosthesis and helps to restore your smile</p>
                        </div>
                    </div>
                </div>
                <div className="wave waves"></div>
                </section>

                <section>
                <div className="nutrition--t--container ">
                    <div className="nutrition--first">
                    </div>
                    <h2 className="t--subheading">NUTRITION MANAGEMENT</h2>
                    <div className="treatment--list--container">
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_n_1} alt="MEDICAL NUTRITION-icon" ></img>
                            <h4 className="s--t--heading">MEDICAL NUTRITION</h4>
                            <p className="s--t--explanation">Medical Nutrition is a therapeutic approach to health improvement through personalized diet/meal plans and individualized nutrition plans. Helpful for managing diabetes, PCOD, PCOS, and other health conditions</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_n_2} alt="FITNESS NUTRITION-icon" ></img>
                            <h4 className="s--t--heading">FITNESS NUTRITION</h4>
                            <p className="s--t--explanation">Fitness Nutrition is focused mainly to maximize fitness performance with well-balanced and highly individualized dietitian-recommended nutrition plans specific to body and activity level</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_n_3} alt="WEIGHT MANAGEMENT-icon" ></img>
                            <h4 className="s--t--heading">WEIGHT MANAGEMENT</h4>
                            <p className="s--t--explanation">Weight Management diet plans emphasize healthy weight loss / healthy weight gain with the help of guides, tools, and science-backed support</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_n_4} alt="CORPORATE NUTRITION-icon" ></img>
                            <h4 className="s--t--heading">CORPORATE NUTRITION</h4>
                            <p className="s--t--explanation">Corporate Nutrition prioritizes improving diet, and lifestyle decisions and reducing weariness among individuals in a fast-paced environment</p>
                        </div>
                        <div className="treatment--list">
                            <img className="img--icon--s" src={icon_n_5} alt="GLOW DIET-icon" ></img>
                            <h4 className="s--t--heading">GLOW DIET (SKIN & HAIR)</h4>
                            <p className="s--t--explanation">Glow Diet plans provide well-balanced nutrition that plays a major role in skin and hair health. Choose radiant hair and glowing skin from within </p>
                        </div>    
                    </div>
                </div>
                <div className="wave waves"></div>
                </section>
                
            </div>
        <div className="t--book">
            <CustomLink to="/bookaconsult">Book a Consult</CustomLink>
        </div>

        </>
    )
}
function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }