import React from "react"
import Navbar_1 from './Navbar_1'
import Home from "./pages/Home"
import About from './pages/About.js'
import Bookaconsult from "./pages/Bookaconsult"
import Treatments from "./pages/Treatments"
import Skin_form from "./pages/Skin_form"
import Hair_form from "./pages/Hair_form" 
import Dental_form from "./pages/Dental_form"
import Nutrition_form from "./pages/Nutrition_form"
import {Routes,Route} from "react-router-dom";

function App(){
    return(
        <>
            <Navbar_1 />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/bookaconsult" element={<Bookaconsult />} />
                <Route path="/treatments" element={<Treatments />} />
                <Route path="/skin_form" element={<Skin_form />} />
                <Route path="/hair_form" element={<Hair_form />} />
                <Route path="/dental_form" element={<Dental_form />} />
                <Route path="/nutrition_form" element={<Nutrition_form />} />
            </Routes>
        </>
    )
}

export default App