import React from "react"
import logo from "./images/logo_blue.png"
import '../src/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import { Nav, Navbar} from 'react-bootstrap'
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle'
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse'
import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar_1() {
  return (
    <div className="nav--bar">
      <Navbar sticky="top" expand="sm" collapseOnSelect>
        <Navbar.Brand>
          <img className="logo" src={logo} alt="logo"/>
        </Navbar.Brand>
        <NavbarToggle />
          <NavbarCollapse>
            <Nav>
                <CustomLink to="/">HOME</CustomLink>
                <CustomLink to="/bookaconsult">BOOK_A_CONSULT</CustomLink>
                <CustomLink to="/treatments">TREATMENTS</CustomLink>
                <CustomLink to="/about">ABOUT_US</CustomLink>
            </Nav>
          </NavbarCollapse>
      </Navbar>
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
