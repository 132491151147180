import '../index.css'
import React from "react"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import slider_skin from "../images/skin_slider_img.png";
import slider_hair from "../images/hair_slider_img.png";
import slider_dental from "../images/dental_slider_img.png";
import slider_nutrition from "../images/nutrition_slider_img.png";
import icon_1 from "../images/how_1_new.png";
import icon_2 from "../images/how_2_new.png";
import icon_3 from "../images/how_3_new.png";
import icon_4 from "../images/how_4_new.png";
import icon_5 from "../images/how_5_new.png";
import icon_6 from "../images/how_6_new.png";
import icon_7 from "../images/how_7_new.png";
import icon_8 from "../images/how_8_new.png";
import icon_9 from "../images/how_9_new.png";

export default function Home(){
    return(
        <>
            {/* CAROUSEL */}
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src={slider_skin} alt="First slide" />
                        <div class="carousel-caption d-md-block">
                            <h5>LOVE THE SKIN YOU'RE IN</h5>
                            <p>BEAUTY HAS NO SKIN TONE</p>
                            <h6><CustomLink to="/bookaconsult" target="_blank">Book a Consult</CustomLink></h6>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={slider_hair} alt="Second slide" />
                        <div class="carousel-caption d-md-block">
                            <h5>INVEST IN YOUR HAIR</h5>
                            <p>IT IS THE CROWN THAT YOU NEVER TAKE OFF</p>
                            <h6><CustomLink to="/bookaconsult" target="_blank">Book a Consult</CustomLink></h6>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={slider_dental} alt="Third slide" />
                        <div class="carousel-caption d-md-block">
                            <h5>THE SMILE YOU DESIRE</h5>
                            <p>THE EXPERIENCE YOU DESERVE</p>
                            <h6><CustomLink to="/bookaconsult" target="_blank">Book a Consult</CustomLink></h6>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={slider_nutrition} alt="Fourth slide" />
                        <div class="carousel-caption d-md-block">
                            <h5>LOOK AFTER YOURSELF FROM WITHIN</h5>
                            <p>YOUR BEAUTY WILL SHINE THROUGH ON YOUR SKIN</p>
                            <h6><CustomLink to="/bookaconsult" target="_blank">Book a Consult</CustomLink></h6>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>

            {/* HOW IT WORKS */}
            <div className="how--container">
                <div className="how--container--heading">
                    <h2>K'ARE AESTHETICS</h2>
                    <p>Skin | Hair | Dental | Nutrition </p>
                </div>
                <div className="how--it--works">
                    <div className="cards">
                        <div className="card--1">
                            <div className="card--1--front">
                                <h3>NEW !?</h3> 
                                <div className="how--two--icons"><img src={icon_1} alt="icon"/><img src={icon_2} alt="icon" /><img src={icon_3} alt="icon" /></div>
                            </div>
                            <div className="card--1--back"><h2>NEW !?</h2><p>Book a FREE counsel from an Expert. </p><p><CustomLink to="/bookaconsult" target="_blank">Book a Consult</CustomLink></p></div>
                        </div>
                        <div className="card--2">
                            <div className="card--2--front">
                                <h3>ONLINE CONSULT</h3>
                                <div className="how--card--2--icons"><img src={icon_4} alt="icon" /><img src={icon_5} alt="icon" /><img src={icon_6} alt="icon" /></div>
                                
                            </div>
                            <div className="card--2--back"><h2>DETAILED COUNSELLING</h2><p>Book an Audio / Video Consultation <p><span><CustomLink to="/skin_form" target="_blank">Skin</CustomLink><CustomLink to="/hair_form" target="_blank">Hair</CustomLink><CustomLink to="/dental_form" target="_blank">Dental</CustomLink><CustomLink to="/nutrition_form" target="_blank">Nutri </CustomLink></span></p></p></div>
                        </div>
                        <div className="card--3">
                            <div className="card--3--front">
                                <h3>WALK-INs</h3> 
                                <div className="how--card--3--icons"><img src={icon_7} alt="icon" /><img src={icon_8} alt="icon" /><img src={icon_9} alt="icon" /></div>
                            </div>
                            <div className="card--3--back"><h2>VISIT OUR CLINIC</h2><p>Check out our<CustomLink to="/about" target="_blank">K'are Clinic</CustomLink></p></div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* FOURSECTIONS */}
            <section className="foursection">
                {/* -------------------------------SKIN--------------------------- */}
                <div className="skin--section">
                    <div className="grid-item-1" data-aos='fade-right'>
                    </div>
                    <div className="grid-item-2 skin--form--block">
                        <div className="skin--form--block--ele">
                            <CustomLink to="/skin_form" target="_blank">
                                S<br />k<br />i<br />n<br /><br />F<br />o<br />r<br />m
                            </CustomLink>
                        </div>
                    </div>
                    <div className='grid-item-3' >
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--treatments--heading">
                                <h3>Our Treatments</h3>
                            </div>
                        </CustomLink>
                    </div>
                    <div className='grid-item-4' data-aos='fade-left'>
                        <CustomLink to="/skin_form" target="_blank" >
                            <div className="our--favourites--heading">
                                <h3>Our Favourites</h3>
                            </div>
                            <div className="our--favourites--caption">
                                GET CUSTOMIZED SKIN PRODUCT SUGGESTIONS FROM K'ARE EXPERTS
                            </div>    
                        </CustomLink>
                    </div>
                    <div className='grid-item-5'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--highlights--heading">
                                <h3>K'are Highlights</h3>
                            </div>
                            <div className="our--highlights--caption">
                                PAINLESS HAIR_FREE LASER TREATMENTS: PRECISION | EFFECTIVE | PERMANENT
                            </div>
                        </CustomLink>
                    </div>
                    <div className='grid-item-6'>
                        <CustomLink to="/bookaconsult" target="_blank" >
                            <div className="our--bookings--heading">
                                <h3>Quick Consult</h3>
                            </div>
                            <div className="our--bookings--caption">
                                BOOK A FREE: ONE-ON-ONE CONSULT WITH OUR K'ARE EXPERT
                            </div>
                        </CustomLink>
                    </div>        
                </div>
            

                {/* -------------------------HAIR-------------------------------------- */}
                <div className="hair--section">
                    <div className="h-grid-item-1" data-aos='fade-right'>
                    </div>
                    <div className="h-grid-item-2 skin--form--block">
                        <div className="skin--form--block--ele">
                            <CustomLink to="/hair_form" target="_blank">
                                H<br />a<br />i<br />r<br /><br />F<br />o<br />r<br />m
                            </CustomLink>
                        </div>
                    </div>
                    <div className='h-grid-item-3'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--treatments--heading">
                                <h3>Our Treatments</h3>
                            </div>
                        </CustomLink>
                    </div>
                    <div className='h-grid-item-4' data-aos='fade-left'>
                        <CustomLink to="/hair_form" target="_blank" >
                            <div className="our--favourites--heading">
                                <h3>Our Favourites</h3>
                            </div>
                            <div className="our--favourites--caption">
                                GET CUSTOMIZED HAIR PRODUCT SUGGESTIONS FROM K'ARE EXPERTS
                            </div>    
                        </CustomLink>
                    </div>
                    <div className='h-grid-item-5'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--highlights--heading">
                                <h3>K'are Highlights</h3>
                            </div>
                            <div className="our--highlights--caption">
                                NATURAL PRP (Platelet-Rich Plasma) THERAPY FOR HAIR GROWTH
                            </div>
                        </CustomLink>
                    </div>
                    <div className='h-grid-item-6'>
                        <CustomLink to="/bookaconsult" target="_blank" >
                            <div className="our--bookings--heading">
                                <h3>Quick Consult</h3>
                            </div>
                            <div className="our--bookings--caption">
                                BOOK A FREE: ONE-ON-ONE CONSULT WITH OUR K'ARE EXPERT
                            </div>
                        </CustomLink>
                    </div>    
                </div>
            

                {/* -------------------------Dental-------------------------------------- */}
                <div className="dental--section">
                    <div className="d-grid-item-1" data-aos='fade-right'>
                    </div>
                    <div className="d-grid-item-2 skin--form--block">
                        <div className="skin--form--block--ele">
                            <CustomLink to="/dental_form" target="_blank">
                                D<br />e<br />n<br />t<br />a<br />l<br /><br />F<br />o<br />r<br />m
                            </CustomLink>
                        </div>
                    </div> 
                    <div className='d-grid-item-3'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--treatments--heading">
                                <h3>Our Treatments</h3>
                            </div>
                        </CustomLink>
                    </div>
                    <div className='d-grid-item-4' data-aos='fade-left'>
                        <CustomLink to="/dental_form" target="_blank" >
                            <div className="our--favourites--heading">
                                <h3>Our Favourites</h3>
                            </div>
                            <div className="our--favourites--caption">
                                GET CUSTOMIZED DENTAL PRODUCT SUGGESTIONS FROM K'ARE EXPERTS
                            </div>    
                        </CustomLink>
                    </div>

                    <div className='d-grid-item-5'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--highlights--heading">
                                <h3>K'are Highlights</h3>
                            </div>
                            <div className="our--highlights--caption">
                                PAINLESS DENTISTRY: MINIMAL INVASIVE SMILE DESIGNING 
                            </div>
                        </CustomLink>
                    </div>
            
                    <div className='d-grid-item-6'>
                        <CustomLink to="/bookaconsult" target="_blank" >
                            <div className="our--bookings--heading">
                                <h3>Quick Consult</h3>
                            </div>
                            <div className="our--bookings--caption">
                                BOOK A FREE: ONE-ON-ONE CONSULT WITH OUR K'ARE EXPERT
                            </div>
                        </CustomLink>
                    </div>                           
                </div>
            

                {/* -------------------------Nutrition-------------------------------------- */}
                <div className="nutrition--section">
                    <div className="n-grid-item-1" data-aos='fade-left'>
                    </div>
                    <div className="n-grid-item-2 skin--form--block">
                        <div className="skin--form--block--ele">
                            <CustomLink to="/nutrition_form" target="_blank">
                                N<br />u<br />t<br />r<br />i<br />t<br />i<br />o<br />n<br /><br />F<br />o<br />r<br />m
                            </CustomLink>
                        </div>
                    </div>  
                    <div className='n-grid-item-3'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--treatments--heading">
                                <h3>Our Treatments</h3>
                            </div>
                        </CustomLink>
                    </div>

                    <div className='n-grid-item-4' data-aos='fade-right'>
                        <CustomLink to="/nutrition_form" target="_blank" >
                            <div className="our--favourites--heading">
                                <h3>Our Favourites</h3>
                            </div>
                            <div className="our--favourites--caption">
                                GET CUSTOMIZED NUTRITIONAL SUPPLEMENT SUGGESTIONS FROM K'ARE EXPERTS
                            </div>    
                        </CustomLink>
                    </div>

                    <div className='n-grid-item-5'>
                        <CustomLink to="/treatments" target="_blank" >
                            <div className="our--highlights--heading">
                                <h3>K'are Highlights</h3>
                            </div>
                            <div className="our--highlights--caption">
                                OVERALL NUTRITIONAL HEALTH: GLOW DIET FOR SKIN & HAIR
                            </div>
                        </CustomLink>
                    </div>
            
                    <div className='n-grid-item-6'>
                        <CustomLink to="/bookaconsult" target="_blank" >
                            <div className="our--bookings--heading">
                                <h3>Quick Consult</h3>
                            </div>
                            <div className="our--bookings--caption">
                                BOOK A FREE: ONE-ON-ONE CONSULT WITH OUR K'ARE EXPERT
                            </div>
                        </CustomLink>
                    </div>                      
                </div>
                
            </section>
            {/* SOCIALS */}
            <div className="icon-bar">
                <a href="http://www.instagram.com/kare_aesthetics/" target="_blank" className="icon--insta"><i className="fa fa-instagram"></i></a>
                <a href="mailto:karebengaluru@gmail.com" target="_blank" className="icon--email"><i className="fa fa-envelope"></i></a>
                <a href="https://wa.me/919591593606" target="_blank" className="icon--whatsapp"><i className="fa fa-whatsapp"></i></a>
                
            </div>

            {/* FOOTER */}
            <div>
            <div className="footer">
                <div className="help"><p id="help--p">Help</p>
                    <div className="tandp"><p><br /><a href="http://www.localhost:3000/termsandconditions.html" target="_blank"><br />Terms&Conditions</a></p><p><a href="http://www.localhost:3000/privacypolicy.html" target="_blank"><br />Privacy Policy</a></p>
                    </div>
                </div>
                <div className="contact--us"><p id="contactus--p">Contact Us</p>
                    <div className="contact--links">
                        <a href="http://www.instagram.com/kare_aesthetics/" target="_blank" className="icon--insta"><i className="fa fa-instagram"></i></a>
                        <a href="mailto:karebengaluru@gmail.com" target="_blank" className="icon--email"><i className="fa fa-envelope"></i></a>
                        <a href="https://wa.me/919591593606" target="_blank" className="icon--whatsapp"><i className="fa fa-whatsapp"></i></a>
                    </div>
                        <p className="fa fa-phone"> +91 9591593606</p>
                </div>
                <div className="address"><p id="address--p">Address</p>
                    <div><p> <br />K'are Aesthetics <br />1st Floor, GM Arcade, Building No.58 <br />Jyoti Nivas College Road, 5th Block <br /> Koramangala Industrial Area <br /> Bangalore - 560095 </p>
                    </div>
                </div>

            </div>

            <div className="copyright">&nbsp;&copy; 2022 <a href="#" >https://kareaesthetics.com/ </a>, K'are Aesthetic Clinic. All rights reserved <br />Icons made by <a href="https://www.freepik.com" title="Freepik"> Freepik </a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com'</a> </div>
            </div>

        </>
    )
}
function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }