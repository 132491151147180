import '../index.css'
// import React from "react"
import React, {useState, useRef} from 'react'
// import book from "../images/book a consult.png"


export default function Bookaconsult(){
    const [formData,setFormData] = React.useState(
        { 
            Name:"",
            Mobile:"",
            Skin:false ,
            Hair:false,
            Nutrition:false,
            Dental:false,
            Mode:"",
            Timeslot:"",
            comments:""
        }
    )
    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }
    console.log(formData)
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbzwxbBrAuGJ03rWhCvikgx-HKDW59R4OmrrcPPdATh-MkRO5Fxti20IqQxIokVFchc/exec"
    const [loading, setLoading] = useState(false)
    function handleSubmit(event){
        event.preventDefault()
        const success = document.getElementById('success')
        success.style.display='block';
        console.log(formData)
        fetch(scriptUrl, {
            method: 'POST', 
            body: new FormData(formRef.current),
    
        }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
                window.location.reload()
            })
            .catch(err => console.log(err))
    }
    return(
        <>
            <div className="bookaconsult">
            <div id="success"><h6>YOUR INFORMATION IS SUBMITTED SUCCESSFULLY! <br />AWAIT A QUICK RESPONSE FROM K'ARE!! </h6></div>
            <div className="lets--begin--container" id="lets">
                <h2>K'ARE</h2>
                <h4>The Finest K'are for the Finest Personalities</h4>
                
                <div className="lets--begin">
                    <div className="lets--begin--header">
                        {/* <img src={book} alt="book a consult" /> */}
                    </div>
                    <div className="lets--form--1">
                        <form ref={formRef} onSubmit={handleSubmit}  target="_new" name="google-sheet" autocomplete="off">
                            <div className="form--name--mob">
                                <label>
                                    NAME * &nbsp;
                                
                                    <input
                                        id="name"
                                        type="text" 
                                        placeholder="FULL NAME"
                                        onChange={handleChange}
                                        name="Name"
                                        value={formData.Name}
                                        required
                                    />
                                </label>
                                <label>
                                    MOBILE * &nbsp;
                                
                                    <input
                                        id="mobile"
                                        type="text"
                                        placeholder="ENTER 10 DIGIT MOBILE NUMBER"
                                        onChange={handleChange}
                                        name="Mobile"
                                        value={formData.Mobile}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form--checkbox">
                                {/* <h3>REQUIRED SERVICES</h3> */}
                                <h3>Required Services &nbsp;</h3>
                                <input 
                                    type="checkbox"
                                    id="Skin"
                                    checked={formData.Skin}
                                    onChange={handleChange}
                                    name="Skin"
                                    
                                />
                                <label htmlFor="Skin"> Skin &nbsp; </label>

                                    <input 
                                        type="checkbox"
                                        id="Hair"
                                        checked={formData.Hair}
                                        onChange={handleChange}
                                        name="Hair"
                                        // value={formData.Service}
                                    />
                                    <label htmlFor="Hair"> Hair &nbsp;</label>

                                    <input 
                                        type="checkbox"
                                        id="Dental"
                                        checked={formData.Dental}
                                        onChange={handleChange}
                                        name="Dental"
                                        // value={formData.Service}
                                    />
                                    <label htmlFor="Dental">Dental &nbsp; </label>

                                    <input 
                                        type="checkbox"
                                        id="Nutrition"
                                        checked={formData.Nutrition}
                                        onChange={handleChange}
                                        name="Nutrition"
                                        // value={formData.Service}
                                    />
                                    <label htmlFor="Nutrition">Nutrition &nbsp;  </label>
                            </div>
                            
                            <div className="form--radios">
                                {/* <h3>PREFERRED MODE OF CONTACT</h3> */}
                                <h3>Preferred Mode of Contact &nbsp;</h3>
                                    <input 
                                        type="radio"
                                        id="call"
                                        name="Mode"
                                        value="call"
                                        checked={formData.Mode === "call"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="call">Call &nbsp;</label>
                                    <input 
                                        type="radio"
                                        id="msg"
                                        name="Mode"
                                        value="msg"
                                        checked={formData.Mode === "msg"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="msg">Message &nbsp;</label>
                                    <input 
                                        type="radio"
                                        id="chat"
                                        name="Mode"
                                        value="chat"
                                        checked={formData.Mode === "chat"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="chat">WhatsApp</label>
                                <br />
                                {/* <h3>PREFERRED TIME SLOT</h3> */}
                                <h3>Preferred Time Slot &nbsp;</h3>
                                    <input 
                                        type="radio"
                                        id="morning"
                                        name="Timeslot" 
                                        value="morning 10am-12pm"
                                        checked={formData.Timeslot ==="morning 10am-12pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="morning">10am- 12pm &nbsp;</label>

                                    <input 
                                        type="radio"
                                        id="noon"
                                        name="Timeslot"
                                        value="noon 2pm-5pm"
                                        checked={formData.Timeslot ==="noon 2pm-5pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="noon">2pm - 5pm &nbsp;</label>

                                    <input 
                                        type="radio"
                                        id="eve"
                                        name="Timeslot"
                                        value="eve 5pm-8pm"
                                        checked={formData.Timeslot ==="eve 5pm-8pm"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="eve">5pm - 8pm</label>
                                </div>
                                <br />
                                <div className="form--comments">
                                    <h3>Concerns / Comments &nbsp;</h3>
                                    {/* <label htmlFor="comments">CONCERNS/COMMENTS</label> */}
                                    <textarea
                                        id="comments"
                                        value={formData.comments}
                                        placeholder="COMMENTS, IF ANY ..."
                                        onChange={handleChange}
                                        name="comments">
                                    </textarea> 
                                </div>
                                <div className="form--button">
                                    {/* <button id="submit" name="submit">Submit</button> */}
                                    <input id="submit" name="submit" type="submit" value={loading ? "Loading..." : "Submit"}/>
                                </div>

                                
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

